<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card flat :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-stepper slot="" v-model="e6" vertical>
        <v-stepper-step editable :complete="e6 > 1" step="1">
          {{ $store.getters.translate("choose_person_type") }}
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-card flat class="ml-5">
            <v-radio-group v-model="record.type" :mandatory="true" row>
              <v-radio value="employee" :label="$store.getters.translate('employee')"/>
              <v-radio value="contact" :label="$store.getters.translate('contact')"/>
            </v-radio-group>
          </v-card>
        </v-stepper-content>
        <v-stepper-step editable :complete="e6 > 2" step="2">
          {{ $store.getters.translate("add_person_contact_info") }}
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card flat class="ml-5">
            <template v-if="stored_people.length > 0">
              <v-alert v-for="(person, index) in stored_people" :key="index" dense outlined type="warning">
                {{ $store.getters.translate("person_already_exists") }}
                <br />
                <a @click="$router.push(getRelationType(person) + 's/' + getRelationId(person))">
                  <small>{{ person.name }} ({{ person[getRelationType(person)].id }})</small>
                </a>
              </v-alert>
            </template>
            <template v-for="field in usual_fields">
              <template v-if="field.name === 'name'">
                <v-text-field
                    :key="field.name + '_first_name'"
                    :label="$store.getters.translate('first_name')"
                    v-model="record.first_name"
                    required
                    validate-on-blur
                    :hint="$store.getters.translate('required')"
                    @blur="checkExistence">
                  <template #label>{{ $store.getters.translate('first_name') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
                <v-text-field
                    :key="field.name + '_insertion'"
                    :label="$store.getters.translate('insertion')"
                    v-model="record.insertion"/>
                <v-text-field
                    :key="field.name + '_last_name'"
                    :label="$store.getters.translate('last_name')"
                    v-model="record.last_name"
                    required
                    validate-on-blur
                    :hint="$store.getters.translate('required')"
                    @blur="checkExistence">
                  <template #label>{{ $store.getters.translate('last_name') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
                <v-text-field
                    :key="field.name + '_initials'"
                    :label="$store.getters.translate('initials')"
                    v-model="record.initials"/>
              </template>
              <template v-else-if="field.name === 'jobtitle'">
                <base-field
                    :key="field.name"
                    :value="record[user_type][field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
              <template v-else>
                <base-field
                    :key="field.name"
                    :value="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
            </template>
            <base-addresses
                v-model="record.addresses"
                :is_submit="is_submit"/>
            <base-email-addresses
                v-model="record.emailaddresses"
                @changes_in_emailaddress="changes_in_emailaddress()"
                :is_submit="is_submit"/>
            <v-switch
                :disabled="record.emailaddresses.length === 0"
                v-model="record.notify"
                :label="$store.getters.translate('send_account_create_notification')"/>
            <v-textarea
              v-if="record.notify"
              v-model="record.message"
              :label="$store.getters.translate('message')"/>
          </v-card>
        </v-stepper-content>
        <v-stepper-step editable :complete="e6 > 3" step="3">
          {{ $store.getters.translate("attach_to_companies") }}
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card flat class="ml-5">
            <v-select
              v-model="record.add_to_companies"
              :items="companies"
              chips
              deletable-chips
              small-chips
              multiple
              item-value="id"
              item-text="name"/>
          </v-card>
        </v-stepper-content>
        <v-stepper-step v-if="tags_required" editable :complete="e6 > 4" step="4">
          {{ $store.getters.translate('tags') }}
        </v-stepper-step>
        <v-stepper-content v-if="tags_required" step="4">
          <v-card flat class="ml-5">
            <base-tags v-model="record.tags" :modal_name="user_type + 's'"/>
          </v-card>
        </v-stepper-content>
        <v-stepper-step v-if="custom_fields.length > 0" editable :complete="e6 > 5" step="5">
          {{ $store.getters.translate('additional_information') }}
        </v-stepper-step>
        <v-stepper-content v-if="custom_fields.length > 0" step="5">
          <v-card flat class="ml-5">
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :key="field.name"
                    :value="record[user_type].custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
          </v-card>
        </v-stepper-content>
      </v-stepper>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseEmailAddresses from "../../components/commonComponents/BaseEmailAddresses";
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseAddresses,
    BaseEmailAddresses,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_person"),
        name: "people",
      },
      e6: 1,
      stored_people: [],
      record: {
        contact: {},
        employee: {},
        emailaddresses: []
      },
      loading: false,
      valid: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      companies: [],
      is_submit: false,
      user_type: "",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(type = null, add_to_companies = []) {
      this.record = {
        type: type,
        contact: {},
        employee: {},
        emailaddresses: [],
        add_to_companies: add_to_companies,
      };
      this.user_type = type;
      this.openModal(this.modal_name);
      this.getCompanies();
      this.getFields();
    },
    changes_in_emailaddress() {
      this.record.emailaddresses = [];
      this.record.notify = false;
    },
    getRelationId(person) {
      if (person.contact) {
        return person.contact.id;
      }
    },
    getRelationType(person) {
      if (person.contact) {
        return "contact";
      }
      if (person.employee) {
        return "employee";
      }
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields, ['name', 'jobtitle'])) {
          this.loading = true;
          if(await helpFunctions.createModel(this.$toasted, 'people', this.record)) {
            this.$emit("refresh");
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      this.record = {
        contact: {},
        employee: {},
        emailaddresses: [],
      };
      this.is_submit = !this.is_submit;
      this.closeModal(this.modal_name);
    },
    checkExistence() {
      if (this.record.first_name && this.record.last_name) {
        this.$http
            .post(this.$store.getters.appUrl + "v2/people/checkexistence", {
              first_name: this.record.first_name,
              last_name: this.record.last_name,
            }).then((response) => {
              this.loading = false;
              response.data.forEach((person) => {
                if (this.record.type == this.getRelationType(person)) {
                  this.stored_people.push(person);
                }
              });
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'jobtitle') {
        this.record[this.user_type][data[0]] = data[1];
      }
      else {
        this.record[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      this.record[this.user_type].custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.user_type, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    getCompanies() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/companies")
        .then((response) => {
          this.loading = false;
          this.companies = this.$lodash.sortBy(response.data.data, "name");
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
  },
};
</script>