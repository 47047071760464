<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save()"
          @archive="archive()"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <div style="width: 100%" class="text-center">
              <v-file-input
                v-if="!record.logo"
                accept="image/*"
                :label="$store.getters.translate('logo')"
                show-size
                @change="saveImage"
                v-model="file"/>
              <v-layout v-else align-center justify-center column fill-height>
                <v-flex row align-center>
                  <v-img position="center" max-width="200" contain :src="record.logo.full_path"/>
                </v-flex>
                <v-btn text color="red" class="mt-2" v-if="canEdit(record.id || 0)" @click="deleteImage()">
                  <v-icon color="error">mdi-trash-can</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <template v-for="field in usual_fields">
              <base-field
                  :isDisabled="!canEdit(record.id || 0)"
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
          </template>
          <template v-slot:module_fields>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
            <email-addresses v-model="record.emailaddresses" @change="save()"/>
            <base-addresses @change="save()" v-model="record.addresses"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="employees_table" :page="page" :parent_module_id="record.id" relation_name="employees" @change="load" @create="createEmployee" @edit="editEmployee"/>
          <base-table-includable ref="contacts_table" :page="page" :parent_module_id="record.id" relation_name="contacts" @change="load" @create="createContact" @edit="editContact"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @refresh="load()"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load()"/>
    <create-person modal_name="create_person" ref="create_person" @refresh="load()"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import CreatePerson from "../people/Create";
import CreateNote from "../notes/Create";
import EditNote from "../notes/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTableIncludable,
    BaseAddresses,
    BaseTabPanel,
    CreatePerson,
    CreateNote,
    EditNote,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("companies"),
        name: "companies",
        model: "company"
      },
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      file: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.employees_table.records = this.record.employees;
      this.$refs.contacts_table.records = this.record.contacts;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.employees_table.selected = [];
      this.$refs.contacts_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    editContact(item) {
      this.$router.push("/contacts/" + item.id);
    },
    editEmployee(item) {
      this.$router.push("/employees/" + item.id);
    },
    createContact() {
      this.$refs.create_person.load("contact", [this.record.id]);
    },
    createEmployee() {
      this.$refs.create_person.load("employee", [this.record.id]);
    },
    createNote() {
      this.$refs.create_note.load();
    },
    saveImage() {
      if(!this.canEdit(this.record.id || 0)) {
        return;
      }
      else {
        this.loading = true;
        const formData = new FormData();
        formData.append("logo", this.file, "name.jpeg");
        this.$http
            .post(this.$store.getters.appUrl + "v2/companies/file?id=" + this.record.id, formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(() => {
              this.loading = false;
              this.load();
            }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      }
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/companies/file/delete?id=" + this.record.id)
        .then(() => {
          this.loading = false;
          this.load();
          this.file = null;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
