<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  :action="'create'"
                  @change="usualFieldDataChanged"/>
            </template>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
            <v-row>
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                    class="mt-3"
                    :label="$store.getters.translate('files')"
                    placeholder="Drop file to upload"
                    show-size
                    v-model="record.files"
                    multiple/>
              </v-col>
            </v-row>
            <base-tags v-if="tags_required" v-model="record.tags" :modal_name="module_name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["module_name", "model_id", "modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("note"),
        name: "notes",
        model: "note"
      },
      loading: false,
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      file: "",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {};
      this.openModal(this.modal_name);
      this.getFields();
    },
    save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          const formData = new FormData();
          if (this.record.files) {
            this.record.files.forEach((file) => {
              formData.append("attachment[]", file);
            });
          }
          formData.append("title", this.record.title);
          formData.append("content", this.record.content);
          formData.append("type", "");
          if (this.module_name && this.model_id) {
            formData.append("model", this.$models[this.module_name]);
            formData.append("model_id", this.model_id);
          }
          formData.append("tags", JSON.stringify(this.record.tags));
          formData.append("generate_document", this.record.generate_document);
          if (this.record.template_id) {
            formData.append("template_id", this.record.template_id);
          }
          formData.append("document_name", this.record.document_name);

          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, formData, {headers: {"Content-Type": "multipart/form-data"}})
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.reset();
                  this.$emit("refresh");
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    addDropFile(e) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        this.record.files.push(e.dataTransfer.files[i]);
      }
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
  },
};
</script>